body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container {
  text-align:center;
}
.container1 {
  margin-top:150px;
  text-align:center;
}
.body1 input{
  width:400px;
  padding:5px;
  border:none;
  outline: None;
  border-bottom: 1px solid black;
}
.body1 input[type="text"]{
  margin-bottom: 10px;
}
.spanclass {
  font-weight: bold;
  font-size:45px;
  font-family: 'Old Standard TT',serif;
  color:#334A5A;
}
.header h1 {
  font-weight:bold;
  font-size:42px;
  font-family:'Old Standard TT',serif;
  color:#0C1824;
}
.body{
  margin-top:50px;
}
.body1 p{
 margin-bottom: 50px;
}
.submitbutton {
  margin-top:50px;
  width:230px;
  border:none;
  background-color:#334A5A;
  color:white;
  padding:10px;
}
.submitbutton:hover {
  background-color:#334A6A;
  cursor: pointer;
}
.box input,.box1 input,.box2 input,.box3 input{
  width:400px;
  padding:5px;
  border:none;
  outline: None;
  margin:5px;
  height:60px;
  color:white;
}
.box input {
  background-color:#022340;
}
.box1 input {
  background-color:#03588C;
}
.box2 input {
  background-color:#024873;
}
.box3 input {
  background-color:#5B89A6;
}
.header h1 {
  text-align:center;
  font-family: 'Old Standard TT',serif;
}