.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.logentry:hover,.projectentry:hover{
  background-color:#F2F2F2;
  cursor:pointer;
}
.logentry,.projectentry {
  border-bottom:1px solid #ccc;
}
.entries {
  text-align:center;
}
.submitbutton1{
  color:white;
  background-color:#334A5A;
  border:none;
  width:100px;
  float:left;
  height:27px;
}
.submitbutton2{
  color:white;
  background-color:#334A5A;
  border:none;
  width:100px;
  margin-left:20px;
}
.backbutton{
  margin-bottom:20px;
  background-color:transparent;
  border:none;
  outline:none;
}
.backbutton:hover {
  border-left: 1px solid #ccc;
  background-color:#F2F2F2;
}
.searchterm {
  float:left;
  width:90%;
  outline:none;
  border:none;
  border-bottom:1px solid #ccc;
  margin-bottom:20px;
  margin-right:10px;
}
.clearallfilters {
  background-color:transparent;
  border:none;
  cursor:pointer;
}
.clearallfilters:hover{
  background-color:#F2F2F2;
  outline:0;
}
.searchterm::placeholder{
  padding-left:10px;
}
.filters{
  margin-left:24px;
  display:flex;
}
.f1 {
  margin-top:1px;
}
.f2{

}
.f13 {
  outline:none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom:1px solid #ccc;
}
.f13::placeholder {
  padding-left:10px;
}