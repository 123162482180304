.b6{

}
.b6 h3 {
}
.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    overflow-y:auto;
    background-color: rgba(0,0,0, 0.6);
}
.popup\_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    padding-top:200px;
    border-radius: 20px;
    background: white;
}
.inner-content{
    background-color:white;
    margin-left:10px;
    margin-right:10px;
}
.b6 {

}
.inner-content button {
    border:none;
    outline:none;
}
.inner-content button:hover{
    background-color:lightgrey;
}
.Test {
    color:red;
}
.Prod {
    color:green;
}
